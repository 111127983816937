import templateUrl from './schedule.tpl.html';
import moment from 'moment';

export default function scheduleComponent($document, SceneCollectionService) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			sceneId: '='
		},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl: templateUrl,
		link: function($scope, $element, attrs, ctrl) {
			function getScheduleFormModel() {
				ctrl.scheduleModel = SceneCollectionService.scheduleFormModel;
			}
			getScheduleFormModel();
			SceneCollectionService.subscribeToFormModel(() => getScheduleFormModel());

			ctrl.upsertSchedule = SceneCollectionService.upsertSchedule;
			ctrl.resetScheduleFormModel = SceneCollectionService.resetScheduleFormModel;
			ctrl.addInterval = SceneCollectionService.appendInterval;
			ctrl.removeInterval = SceneCollectionService.removeInterval;

			ctrl.scheduleDates = [];
			const today = moment();
			let fromDate = today.startOf('day');
			if (today.isSameOrBefore(fromDate)) {
				fromDate = today;
			}
			const toDate = moment().add(10, 'days');
			while (fromDate.isSameOrBefore(toDate)) {
				const day = moment(fromDate);
				ctrl.scheduleDates.push({
					readable: day.format('dddd [(]DD/MM[)]'),
					iso: day.format()
				});
				fromDate.add(1, 'day');
			}

			$scope.$on('$destroy', () => {
				SceneCollectionService.resetScheduleFormModel();
			});
		}
	};
}
